
  .viewModal{
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll; 
  }

  .viewModal::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
