.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-button-prev,
.swiper-button-next {
    color: rgb(82, 82, 99) !important;
}

.swiper-button-prev:after,
.swiper-button-next:after {
    font-size: 30px !important;
}